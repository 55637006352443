import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import StickyBox from "react-sticky-box";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setLaudar as setLaudarAction } from '../../redux/actions/laudo';
import axios from 'axios';
import { getHeaders } from '../../request';
import { SttGrid, SttAlerta, SttButton, SttHidden, SttLoading, SttTranslateHook } from '@stt-componentes/core';
import Laudo from './laudo';
import Imagens from './imagens';
import Invalidar from '../exame/invalidar';
import ComparacaoExames from './comparacao';

const useStyles = makeStyles(theme => ({
    wrapperBotao: {
        display: 'flex',
        justifyContent: 'flex-end',
        '@media (min-width: 0px) and (max-width: 576px)': {
            justifyContent: 'flex-start'
        }
    },
    modalBody: {
        overflow: 'hidden'
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const ContainerLaudo = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { exame, examesComparacao, setExamesComparacao, idRede, setLaudar, callbackProximoExame, notificar } = props;

    const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;

    const location = useLocation();
    const classes = useStyles();
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [contextoFabricalaudo] = useState(location.pathname === '/laudo');
    const [atencao, setAtencao] = useState(false);

    // Invalidação do exame
    const [invalidar, setInvalidar] = useState(false);

    useEffect(() => {
        const paginaLaudo = location.pathname === "/laudo";
    
        return () => {
            if (paginaLaudo) {
                cancelarEmissao();
            }
        };
    }, [location.pathname]);

    const handleCloseAlerta = () => {
        if (contextoFabricalaudo) {
            callbackProximoExame();
        } else {
            setLaudar(false);
        }
    };

    const [opcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);

    const voltar = () => {
        // Controle de componentes. Volta para tela anterior
        setLaudar(false);
    };

    const cancelarEmissao = () => {
        axios
            .post(`${ELETRO_API_BASE_URL}/cancelar-laudo`, { idExame: exame.exame.id_exame }, { headers: getHeaders() })
            .catch(err => console.log(err));
    };

    const ignorarExame = () => {
        notificar(strings.ignorandoExame);
        // Insere exame ignorado
        axios
            .post(`${ELETRO_API_BASE_URL}/ignorar-exame`, { idExame: exame.exame.id_exame }, { headers: getHeaders(), timeout: 15000 })
            .then((resposta) => {
                // Carrega o próximo exame
                callbackProximoExame();
            })
            .catch(err => {
                console.log(err);
                setMensagemErro(strings.erroIgnorarExame);
                setErro(true);
            });
    }

    const ignorarExamesComparacao = () => {
        setExamesComparacao([]);
    };

    const callbackInvalidarExamesComparacao = () => {
        setExamesComparacao(null);
        callbackProximoExame();
    };

    const DivBotoes = () => {
        return (
            <>
                {
                    exame && examesComparacao && examesComparacao.length === 0 &&
                    <SttGrid container spacing={3}>
                        <SttGrid item xs={12} className={classes.wrapperBotao}>
                            {
                                <SttButton
                                    variant="contained"
                                    danger={+true}
                                    onClick={() => setInvalidar(true)}
                                >
                                    {strings.invalidar}
                                </SttButton>
                            }

                            {
                                !contextoFabricalaudo &&
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={voltar}
                                >
                                    {strings.voltar}
                                </SttButton>
                            }

                            {
                                contextoFabricalaudo &&
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={ignorarExame}
                                >
                                    {strings.proximoExame}
                                </SttButton>
                            }

                        </SttGrid>
                    </SttGrid>
                }
            </>
        );
    }

    return (
        <>
            <div className={classes.carregando}>
                <SttLoading
                    open={!exame && !contextoFabricalaudo}
                    text={strings.carregandoExame}
                />
            </div>
            {
                exame && examesComparacao && examesComparacao.length > 0 &&
                <>
                    <ComparacaoExames
                        exameAtual={exame}
                        examesComparacao={examesComparacao}
                        callbackInvalidar={callbackInvalidarExamesComparacao}
                        callbackIgnorarExamesComparacao={ignorarExamesComparacao}
                        ignorarExame={ignorarExame}
                    />
                </>
            }
            {
                exame && examesComparacao && examesComparacao.length === 0 &&
                <>
                    <SttGrid container spacing={3}>
                        <SttHidden mdUp>
                            <SttGrid item xs={12}>
                                <Imagens idExame={exame.exame.id_exame} anexos={exame.anexos} />
                            </SttGrid>
                            <SttGrid item xs={12}>
                                <Laudo dados={exame} callbackProximoExame={callbackProximoExame} DivBotoes={DivBotoes} />
                            </SttGrid>
                        </SttHidden>
                        <SttHidden only={['xs', 'sm']}>
                            <SttGrid item xs={8}>
                                <StickyBox>
                                    <Imagens idExame={exame.exame.id_exame} anexos={exame.anexos} />
                                </StickyBox>
                            </SttGrid>
                            <SttGrid item xs={4}>
                                <Laudo dados={exame} callbackProximoExame={callbackProximoExame} DivBotoes={DivBotoes} />
                            </SttGrid>
                        </SttHidden>
                    </SttGrid>
                </>
            }
            {
                invalidar &&
                <Invalidar
                    invalidar={invalidar}
                    setInvalidar={setInvalidar}
                    invalidarSemConfirmacao={true}
                    idExame={exame.exame.id_exame}
                    idRede={idRede}
                    callback={callbackProximoExame}
                />
            }
            <SttAlerta
                open={erro}
                title={strings.erro}
                message={mensagemErro}
                type="error"
                options={opcoesAlerta}
                onClose={handleCloseAlerta}
            />
            <SttAlerta
                open={atencao}
                title={strings.atencao}
                message={strings.laudoEmEmissao}
                type="alert"
                options={opcoesAlerta}
                onClose={handleCloseAlerta}
            />
        </>
    );
};

ContainerLaudo.propTypes = {
    user: PropTypes.object.isRequired,
    callbackProximoExame: PropTypes.func,
    callbackExameCarregado: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLaudar: laudar => dispatch(setLaudarAction(laudar))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerLaudo);