import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { setAtualizarBusca as setAtualizarBuscaAction } from '../../redux/actions/exame';
import {
    SttButton,
    SttContainer,
    SttTranslateHook,
} from '@stt-componentes/core';
import DadosExame from './dados-exame';

const Visualizacao = (props) => {
    const { user, imprimirFn, imprimirProtocoloFn, imprimirTermoFn, setAtualizarBusca } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { state } = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [idExame, setIdExame] = useState(null);

    const voltar = () => {
        navigate(-1);
    }

    useEffect(() => {
        if (state?.exame) {
            setIdExame(state.exame);
        }
    }, [state]);

    const handleClickVoltar = () => {
        setAtualizarBusca(true);
        voltar();
    };

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={handleClickVoltar}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                idExame &&
                <DadosExame
                    idExame={idExame}
                    voltar={voltar}
                    user={user}
                    imprimirFn={imprimirFn}
                    imprimirProtocoloFn={imprimirProtocoloFn}
                    imprimirTermoFn={imprimirTermoFn}
                    setAtualizarBusca={setAtualizarBusca}
                />
            }
        </SttContainer>
    )

}

Visualizacao.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setAtualizarBusca: atualizar => dispatch(setAtualizarBuscaAction(atualizar))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Visualizacao);