import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setLaudar as setLaudarAction } from '../../redux/actions/laudo';
import axios from 'axios';
import { getHeaders } from '../../request';
import { SttLoading, SttTranslateHook, SttTabs, SttAlerta } from '@stt-componentes/core';
import { useMoment } from '../../hooks';
import ContainerLaudo from './containerLaudo';
import HistoricoPaciente from './historicoPaciente';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    tabs: {
        padding: 0
    }
}));

const Index = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { idExame, idRede, setLaudar, callbackProximoExame, callbackExameCarregado, notificar } = props;

    const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;
    const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;

    const location = useLocation();
    const moment = useMoment();
    const classes = useStyles();
    const [exame, setExame] = useState(null);
    const [examesComparacao, setExamesComparacao] = useState(null);
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [contextoFabricalaudo] = useState(location.pathname === '/laudo');
	const [abas, setAbas] = useState([]);
	const [abaAtiva, setAbaAtiva] = useState('1');
    const [atencao, setAtencao] = useState(false);
    const [carregando, setCarregando] = useState(true);

    const handleCloseAlerta = () => {
        if (contextoFabricalaudo) {
            callbackProximoExame();
        } else {
            setLaudar(false);
        }
    };

    const [opcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);

    const tratarDadosExame = (dados) => {
        // Tratar dados dos motivos de solicitação
        if (dados.indicacaoEletro.motivosSolicitacao?.length) {
            let motivosSolicitacao = '';
            dados.indicacaoEletro.motivosSolicitacao.forEach((motivo, indice) => {
                if (motivo.tipo) {
                    motivosSolicitacao += `${motivo.descricao} (${motivo.tipo_formatado})`;
                } else {
                    motivosSolicitacao += motivo.descricao;
                }

                if (dados.motivosSolicitacao && indice < dados.motivosSolicitacao.length - 1) {
                    motivosSolicitacao += ', ';
                }
            });

            dados.indicacaoEletro.motivosSolicitacaoFormatado = motivosSolicitacao;
        };

        // Tratar dados da indicação clínica
        if (dados.indicacaoEletro.dorIsquemica) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorIsquemica = dados.indicacaoEletro.dorIsquemica.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.dorNaoIsquemica) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorNaoIsquemica = dados.indicacaoEletro.dorNaoIsquemica.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.dorNaoCardiaca) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorNaoCardiaca = dados.indicacaoEletro.dorNaoCardiaca.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.dorToracica) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorToracica = dados.indicacaoEletro.dorToracica.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.fatorRisco) {
            dados.indicacaoEletro.descricaoIndicacaoEletroFatorRisco = dados.indicacaoEletro.fatorRisco.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.localizacaoDor) {
            dados.indicacaoEletro.descricaoIndicacaoEletroLocalizacaoDor = dados.indicacaoEletro.localizacaoDor.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.medicamento) {
            dados.indicacaoEletro.descricaoIndicacaoEletroMedicamento = dados.indicacaoEletro.medicamento.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.motivoExame) {
            dados.indicacaoEletro.descricaoIndicacaoEletroMotivoExame = dados.indicacaoEletro.motivoExame.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.sintomaDor) {
            dados.indicacaoEletro.descricaoIndicacaoEletroSintomaDor = dados.indicacaoEletro.sintomaDor.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.valvulopatia) {
            dados.indicacaoEletro.descricaoIndicacaoEletroValvulopatia = dados.indicacaoEletro.valvulopatia.map(e => e.descricao).join(', ');
        }

        if (dados.exame?.dorToracica) {
            dados.exame.descricaoDorToracicaRealizacaoExame = dados.exame.dorToracica.map(e => e.descricao).join(', ');
        }

        if (dados.exame?.localizacaoDor) {
            dados.exame.descricaoLocalizacaoDorRealizacaoExame = dados.exame.localizacaoDor.map(e => e.descricao).join(', ');
        }

        if (dados.exame?.tipoDor) {
            dados.exame.descricaoTipoDorRealizacaoExame = dados.exame.tipoDor.map(e => {
                return e.complemento ? e.complemento : e.descricao
            }).join(', ');
        }

        return dados;
    };

    const verificarExamesComparacao = (data) => {
        const params = {
            idInstituicao: data.exame.id_instituicao,
            idExame: data.exame.id_exame,
            dataNascimentoPaciente: data.exame.data_nascimento_paciente,
            idSexoPaciente: data.exame.id_sexo_paciente
        }

        axios
            .get(`${ELETRO_API_BASE_URL}/comparar-exames`, { params: params, headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    let { data } = response;
                    setExamesComparacao(data);
                }
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                let arrMensagem = [];
                if (response) {
                    const { data } = response;
                    data.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    msg = arrMensagem.join('\n');
                }
                setMensagemErro(msg);
                setErro(true);
            });
    };    

    useEffect(() => {
        if (idExame) {
            setCarregando(true)
            axios
                .get(`${ELETRO_API_BASE_URL}/laudo/${idExame}/fabrica/${contextoFabricalaudo}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        let { data } = response.data;
                        let dataMoment = moment(data.exame.data_nascimento_paciente);
                        if (dataMoment.isValid()) {
                            var now = moment();
                            var dataMomentFormatada = moment(dataMoment, 'DD-MM-YYYY');
                            var idade = moment.duration(now.diff(dataMomentFormatada));
                            var anos = idade.years();
                            data.exame.data_nascimento_paciente_formatada += ` (${anos} - ${anos+1} anos)`
                        }
                        // Caso seja fábrica de laudos, deve-se verificar exames repetidos para comparação
                        // Caso não seja, seta o array para vazio para que o sistema abra a tela de laudos
                        if (contextoFabricalaudo) {
                            verificarExamesComparacao(data);
                        } else {
                            setExamesComparacao([]);
                        }
                        setExame(tratarDadosExame(data));
                        if (contextoFabricalaudo) {
                            callbackExameCarregado();
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                    const { response } = err;
                    let msg = strings.mensagemErroGeral;
                    let arrMensagem = [];
                    if (response) {
                        const { data } = response;
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                    }
                    setMensagemErro(msg);
                    setErro(true);
                })
                .finally(() => {
                    setCarregando(false)
                })
        }

        return () => {
            setLaudar(false);
        }
    }, []);

    useEffect(() => {
		if (exame) {
            setCarregando(true)
			axios
				.get(`${EXAMES_API_BASE_URL}/laudo/historico/${idExame}`, { headers: getHeaders() })
				.then((response) => {
					if (response.data) {
						if (response.data.data.length > 0) {
							let dadosAbas = [];

							// Aba histórico
							dadosAbas.push({
								historico: response.data.data,
								titulo: strings.historico,
								permanente: true,
								setAbas,
								setAbaAtiva,
                                strings,
								conteudo: HistoricoPaciente
							});

							// Aba laudo
							dadosAbas.push({
								exame: exame,
								idRede,
								callbackProximoExame,
								callbackExameCarregado,
								notificar,
								permanente: true,
								setLaudar: setLaudar,
								titulo: strings.laudo,
								conteudo: ContainerLaudo,
                                examesComparacao: examesComparacao,
                                setExamesComparacao: setExamesComparacao
							});

							setAbas(dadosAbas);
						}
					}
				})
				.catch((err) => console.log(err))
                .finally(() => {
                    setCarregando(false)
                })
		}
	}, [exame, examesComparacao]);

    return (
        <>
            { carregando ? (
                <div className={classes.carregando}>
                    <SttLoading 
                        open={!exame && !contextoFabricalaudo}
                        text={strings.carregandoExame} 
                    />
                </div>
            ) : (
                exame && 
                ((abas.length > 0 && (
                    <SttTabs
                        className={classes.tabs}
                        abaAtiva={abaAtiva}
                        setAbaAtiva={setAbaAtiva}
                        setAbas={setAbas}
                        abas={abas}
                        permanente={true}
                        canClose={true}
                        handleCloseTab={(indice) => {
                            abas.splice(indice, 1);
                            setAbaAtiva(abas.length - 1 + '');
                        }}
                    />
                )) || (
                    <ContainerLaudo
                        exame={exame}
                        examesComparacao={examesComparacao}
                        setExamesComparacao={setExamesComparacao}
                        idRede={idRede}
                        setLaudar={setLaudar}
                        callbackProximoExame={callbackProximoExame}
                        callbackExameCarregado={callbackExameCarregado}
                        notificar={notificar}
                    />
                ))
            )}
            <SttAlerta
                open={erro}
                title={strings.erro}
                message={mensagemErro}
                type="error"
                options={opcoesAlerta}
                onClose={handleCloseAlerta}
            />
            <SttAlerta
                open={atencao}
                title={strings.atencao}
                message={strings.laudoEmEmissao}
                type="alert"
                options={opcoesAlerta}
                onClose={handleCloseAlerta}
            />
        </>
    );
};

Index.propTypes = {
    idExame: PropTypes.number.isRequired,
    callbackProximoExame: PropTypes.func,
    callbackExameCarregado: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLaudar: laudar => dispatch(setLaudarAction(laudar))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);