import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Moment from 'react-moment';
import moment from 'moment';
import {
	SttTable,
	SttTableHead,
	SttTableBody,
	SttTableRow,
	SttTableCell,
	SttContainer,
	SttHidden,
	SttText
} from '@stt-componentes/core';
import { Chip, IconButton } from '@material-ui/core';
import { ListaExames } from '@stt-utilitarios/core';
import DadosExame from '../exame/dados-exame';

const useStyles = makeStyles((theme) => ({
	tableWrapper: {
		marginTop: theme.spacing(3)
	}
}));

const VisualizacaoExame = (props) => {
	return (
		<SttContainer>
			<DadosExame
				{...props}
				ocultarBotoes={true}
			/>
		</SttContainer>
	);
};

const HistoricoPaciente = (props) => {
	const { user, strings, historico, setAbaAtiva, setAbas } = props;
	const classes = useStyles();

	const abrirExame = (exame) => {
		setAbas((abas) => {
			const titulo = `Data do exame: ${moment(exame.data).format('DD/MM/YYYY')}\nRequisição: ${exame.requisicao}`;
			const aba = {
				titulo: titulo,
				idExame: exame.id,
				conteudo: (props) => <VisualizacaoExame {...props} strings={strings} user={user}/>
			};
			return [...abas, aba];
		});
		setAbaAtiva((prev) => String(Number(prev) + 1));
	};

	return (
		<div className={classes.tableWrapper}>
			<SttTable>
				<SttTableHead>
					<SttTableRow>
						<SttHidden smDown>
							<SttTableCell>{strings.dataExame}</SttTableCell>
							<SttTableCell>{strings.requisicao}</SttTableCell>
							<SttTableCell>{strings.descricao}</SttTableCell>
							<SttTableCell>{strings.situacao}</SttTableCell>
							<SttTableCell align="center">{strings.opcoes}</SttTableCell>
						</SttHidden>
					</SttTableRow>
					<SttTableRow>
						<SttHidden mdUp>
							<SttTableCell width="100%">{strings.dadosExame}</SttTableCell>
							<SttTableCell align="center">{strings.opcoes}</SttTableCell>
						</SttHidden>
					</SttTableRow>
				</SttTableHead>
				<SttTableBody>
					{historico.map((row, index) => (
						<SttTableRow key={index}>
							<SttHidden smDown>
								<SttTableCell>{<Moment format="DD/MM/YYYY HH:mm">{row.data}</Moment>}</SttTableCell>
								<SttTableCell>{row.requisicao}</SttTableCell>
								<SttTableCell>{row.descricao}</SttTableCell>
								<SttTableCell>
									<Chip
										style={{ backgroundColor: ListaExames.getCorLegenda(row) }}
										label={ListaExames.getTextoLegenda(row, strings)}
										size="small"
									/>
								</SttTableCell>
								<SttTableCell align="center">
									<IconButton>
										<VisibilityIcon onClick={() => abrirExame(row)} />
									</IconButton>
								</SttTableCell>
							</SttHidden>
							<SttHidden mdUp>
								<SttTableCell>
									<SttText size="small">
										<b>{strings.data}: </b>
										<Moment format="DD/MM/YYYY HH:mm">{row.data_exame}</Moment>
									</SttText>
									<SttText size="small">
										<b>{strings.requisicao}: </b> {row.requisicao}
									</SttText>
									<SttText size="small">
										<b>{strings.descricao}: </b> {row.descricao_exame}
									</SttText>
									<SttText size="small">
                                        <b>{strings.situacao}:</b>
                                        <Chip
                                            style={{ backgroundColor: ListaExames.getCorLegenda(row) }}
                                            label={ListaExames.getTextoLegenda(row, strings)}
                                            size="small" 
                                        />
                                    </SttText>
								</SttTableCell>
								<SttTableCell align="center">
									<IconButton>
										<VisibilityIcon onClick={() => abrirExame(row)} />
									</IconButton>
								</SttTableCell>
							</SttHidden>
						</SttTableRow>
					))}
				</SttTableBody>
			</SttTable>
		</div>
	);
};

HistoricoPaciente.propTypes = {
	historico: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default HistoricoPaciente;