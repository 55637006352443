import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import axios from 'axios';
import { temPerfilRBAC, temPermissaoRBAC } from '../../security/acl';
import { PERFIL, PERMISSOES, PRODUTIVIDADE } from '../../common/Constants';
import {
    SttContainer,
    SttCircularProgress,
    SttGrid,
    SttCard,
    SttCardHeader,
    SttCardContent,
    SttTable,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableHead,
    SttTranslateHook
} from '@stt-componentes/core';
import { getHeaders } from '../../request';
import { useMoment } from '../../hooks';
import { v4 as uuidv4 } from 'uuid';
import upperFirst from 'lodash.upperfirst';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    container: {
        paddingTop: theme.spacing(3)
    },
    card: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },
    table: {
        marginBottom: theme.spacing(3)
    },
    botao: {
        width: '100%',
        padding: theme.spacing(2),
        textAlign: 'center'
    }
}));

const Produtividade = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { user } = props;
    const classes = useStyles();
    const moment = useMoment();
    
    const laudadosController = useRef();
    const laudo72HorasController = useRef();
    const semLaudoController = useRef();
    const validosController = useRef();
    const invalidosController = useRef();

    // Dados de produtividade
    const [produtividadeMesAtual, setProdutividadeMesAtual] = useState({
        mes: null,
        dados: null
    });
    const [produtividadeMesAnterior, setProdutividadeMesAnterior] = useState({
        mes: null,
        dados: null
    });
    const [produtividadeSemLaudo72Horas, setProdutividadeSemLaudo72Horas] = useState({
        dados: null
    });
    const [produtividadeExamesSemLaudo, setProdutividadeExamesSemLaudo] = useState({
        dados: null
    });
    const [produtividadeExamesAntigosSemLaudo, setProdutividadeExamesAntigosSemLaudo] = useState({
        dados: null
    });
    const [produtividadeExamesValidosMesAtual, setProdutividadeExamesValidosMesAtual] = useState({
        mes: null,
        dados: null
    });
    const [produtividadeExamesValidosMesAnterior, setProdutividadeExamesValidosMesAnterior] = useState({
        mes: null,
        dados: null
    });
    const [produtividadeExamesInvalidosMesAtual, setProdutividadeExamesInvalidosMesAtual] = useState({
        mes: null,
        dados: null
    });
    const [produtividadeExamesInvalidosMesAnterior, setProdutividadeExamesInvalidosMesAnterior] = useState({
        mes: null,
        dados: null
    });

    const abortAllControllers = () => {
        if (laudadosController.current) {
            laudadosController.current.abort();
        }

        if (laudo72HorasController.current) {
            laudo72HorasController.current.abort();
        }

        if (semLaudoController.current) {
            semLaudoController.current.abort();
        }

        if (validosController.current) {
            validosController.current.abort();
        }

        if (invalidosController.current) {
            invalidosController.current.abort();
        }
    }

    useEffect(() => {
        const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;
        abortAllControllers();
        if (user.perfisRBAC) {
            const mesAtual = moment().month(); // Começa com 0: janeiro = 0; fevereiro = 1, ..., dezembro = 11
            const mesAnterior = moment().subtract(1, 'month').month()
            if (temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) || temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME)) {
                laudadosController.current = new AbortController();
                laudo72HorasController.current = new AbortController();
                semLaudoController.current = new AbortController();
                //Produtividade do mês atual
                //Produtividade do mês anterior
                axios
                    .get(`${ELETRO_API_BASE_URL}/totais?produtividade=${PRODUTIVIDADE.LAUDADOS}`, { headers: getHeaders(), timeout: 45000, signal: laudadosController.current.signal })
                    .then((response) => {
                        if (response.data) {
                            let laudadosMesAtual = { mes: mesAtual + 1, dados: response.data.filter(item => item.mes === (mesAtual + 1)) };
                            setProdutividadeMesAtual(laudadosMesAtual);

                            let laudadosMesAnterior = { mes: mesAnterior + 1, dados: response.data.filter(item => item.mes === (mesAnterior + 1)) };
                            setProdutividadeMesAnterior(laudadosMesAnterior);
                        }
                    })
                    .catch(err => console.log(err));

                //Laudo atrasado a 72h
                axios
                    .get(`${ELETRO_API_BASE_URL}/totais?produtividade=${PRODUTIVIDADE.LAUDO_72_HORAS}`, { headers: getHeaders(), timeout: 45000, signal: laudo72HorasController.current.signal })
                    .then((response) => {
                        if (response.data) {
                            if (response.data) {
                                setProdutividadeSemLaudo72Horas({ dados: response.data });
                            }
                        }
                    })
                    .catch(err => console.log(err));

                //Sem laudo
                //Exames mais antigos sem laudo
                axios
                    .get(`${ELETRO_API_BASE_URL}/totais?produtividade=${PRODUTIVIDADE.SEM_LAUDO}`, { headers: getHeaders(), timeout: 45000, signal: semLaudoController.current.signal })
                    .then((response) => {
                        if (response.data) {
                            if (response.data) {
                                setProdutividadeExamesSemLaudo({ dados: response.data });
                                setProdutividadeExamesAntigosSemLaudo({ dados: response.data });
                            }
                        }
                    })
                    .catch(err => console.log(err));
            } else {
                validosController.current = new AbortController();
                invalidosController.current = new AbortController();

                axios
                    .get(`${ELETRO_API_BASE_URL}/totais?produtividade=${PRODUTIVIDADE.VALIDOS}`, { headers: getHeaders(), timeout: 45000, signal: validosController.current.signal })
                    .then((response) => {
                        if (response.data) {
                            if (response.data) {
                                let validosMesAtual = 0;
                                let validosMesAnterior = 0;
                                validosMesAtual = { mes: mesAtual + 1, dados: response.data.filter(item => item.mes === (mesAtual + 1)) };
                                validosMesAnterior = { mes: mesAnterior + 1, dados: response.data.filter(item => item.mes === (mesAnterior + 1)) };
                                setProdutividadeExamesValidosMesAtual(validosMesAtual);
                                setProdutividadeExamesValidosMesAnterior(validosMesAnterior);
                            }
                        }
                    })
                    .catch(err => console.log(err));

                axios
                    .get(`${ELETRO_API_BASE_URL}/totais?produtividade=${PRODUTIVIDADE.INVALIDOS}`, { headers: getHeaders(), timeout: 45000, signal: invalidosController.current.signal })
                    .then((response) => {
                        if (response.data) {
                            if (response.data) {
                                let invalidosMesAtual = 0;
                                let invalidosMesAnterior = 0;
                                invalidosMesAtual = { mes: mesAtual + 1, dados: response.data.filter(item => item.mes === (mesAtual + 1)) };
                                invalidosMesAnterior = { mes: mesAnterior + 1, dados: response.data.filter(item => item.mes === (mesAnterior + 1)) };
                                setProdutividadeExamesInvalidosMesAtual(invalidosMesAtual);
                                setProdutividadeExamesInvalidosMesAnterior(invalidosMesAnterior);
                            }
                        }
                    })
                    .catch(err => console.log(err));
            }
        }
    }, [user]);

    useEffect(() => {
        return(() => {
            abortAllControllers();
        })
    }, []);

    return (
        <SttContainer className={classes.container}>
            <SttGrid container spacing={3}>
                {

                    (temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) &&
                    <>
                        {/* Coluna esquerda */}
                        <SttGrid item xs={12} md={6}>
                            {
                                // Caso possua card de laudos por mês atual/anterior
                                <SttCard className={classes.card} variant="outlined">
                                    <SttCardHeader title={strings.laudosEmitidos} component="h3" />
                                    <SttCardContent>
                                        {
                                            produtividadeMesAtual.dados ?
                                                <div className={classes.table}>
                                                    <SttTable>
                                                        <SttTableHead>
                                                            <SttTableRow hover={false}>
                                                                <SttTableCell colSpan={2}>{upperFirst(moment(produtividadeMesAtual.mes, 'M').format('MMMM'))}</SttTableCell>
                                                            </SttTableRow>
                                                        </SttTableHead>
                                                        <SttTableBody>
                                                            {
                                                                produtividadeMesAtual.dados?.length > 0 && produtividadeMesAtual.dados.map(item => (
                                                                    <SttTableRow key={item.id} hover={false}>
                                                                        <SttTableCell>{item.nome}</SttTableCell>
                                                                        <SttTableCell align="right">{item.total}</SttTableCell>
                                                                    </SttTableRow>
                                                                )) ||
                                                                <SttTableRow hover={false}>
                                                                    <SttTableCell>{strings.nenhumRegistroEncontrado}</SttTableCell>
                                                                </SttTableRow>
                                                            }
                                                        </SttTableBody>
                                                    </SttTable>
                                                </div> :
                                                <div className={classes.carregando}>
                                                    <SttCircularProgress color="primary" />
                                                </div>
                                        }
                                        {
                                            produtividadeMesAnterior.dados ?
                                                <div className={classes.table}>
                                                    <SttTable>
                                                        <SttTableHead>
                                                            <SttTableRow hover={false}>
                                                                <SttTableCell colSpan={2}>{upperFirst(moment(produtividadeMesAnterior.mes, 'M').format('MMMM'))}</SttTableCell>
                                                            </SttTableRow>
                                                        </SttTableHead>
                                                        <SttTableBody>
                                                            {
                                                                produtividadeMesAnterior.dados?.length > 0 && produtividadeMesAnterior.dados.map(item => (
                                                                    <SttTableRow key={item.id} hover={false}>
                                                                        <SttTableCell>{item.nome}</SttTableCell>
                                                                        <SttTableCell align="right">{item.total}</SttTableCell>
                                                                    </SttTableRow>
                                                                )) ||
                                                                <SttTableRow hover={false}>
                                                                    <SttTableCell>{strings.nenhumRegistroEncontrado}</SttTableCell>
                                                                </SttTableRow>
                                                            }
                                                        </SttTableBody>
                                                    </SttTable>
                                                </div> :
                                                <div className={classes.carregando}>
                                                    <SttCircularProgress color="primary" />
                                                </div>
                                        }
                                    </SttCardContent>
                                </SttCard>
                            }
                        </SttGrid>

                        {/* Coluna direita */}
                        {
                            <SttGrid item xs={12} md={6}>
                                {
                                    <SttCard key={uuidv4()} className={classes.card} variant="outlined">
                                        <SttCardHeader title={strings.semLaudo72h} component="h3" />
                                        <SttCardContent>
                                            {
                                                produtividadeSemLaudo72Horas.dados ?
                                                    <div className={classes.table}>
                                                        <SttTable>
                                                            <SttTableBody>
                                                                {
                                                                    produtividadeSemLaudo72Horas.dados.length > 0 && produtividadeSemLaudo72Horas.dados.map(item => (
                                                                        <SttTableRow key={item.id} hover={false}>
                                                                            <SttTableCell>{item.nome}</SttTableCell>
                                                                            {item.total && <SttTableCell align="right">{item.total}</SttTableCell>}
                                                                            {!item.total && <SttTableCell align="right">{item.data_exame_mais_antigo}</SttTableCell>}
                                                                        </SttTableRow>
                                                                    )) ||
                                                                    <SttTableRow hover={false}>
                                                                        <SttTableCell>{strings.nenhumRegistroEncontrado}</SttTableCell>
                                                                    </SttTableRow>
                                                                }
                                                            </SttTableBody>
                                                        </SttTable>
                                                    </div> :
                                                    <div className={classes.carregando}>
                                                        <SttCircularProgress color="primary" />
                                                    </div>
                                            }
                                        </SttCardContent>
                                    </SttCard>
                                }

                                {
                                    <SttCard key={uuidv4()} className={classes.card} variant="outlined">
                                        <SttCardHeader title={strings.semLaudo} component="h3" />
                                        <SttCardContent>
                                            {
                                                produtividadeExamesSemLaudo.dados ?
                                                    <div className={classes.table}>
                                                        <SttTable>
                                                            <SttTableBody>
                                                                {
                                                                    produtividadeExamesSemLaudo.dados.length > 0 && produtividadeExamesSemLaudo.dados.map(item => (
                                                                        <SttTableRow key={item.id} hover={false}>
                                                                            <SttTableCell>{item.nome}</SttTableCell>
                                                                            <SttTableCell align="right">{item.total}</SttTableCell>
                                                                        </SttTableRow>
                                                                    )) ||
                                                                    <SttTableRow hover={false}>
                                                                        <SttTableCell>{strings.nenhumRegistroEncontrado}</SttTableCell>
                                                                    </SttTableRow>
                                                                }
                                                            </SttTableBody>
                                                        </SttTable>
                                                    </div> :
                                                    <div className={classes.carregando}>
                                                        <SttCircularProgress color="primary" />
                                                    </div>
                                            }
                                        </SttCardContent>
                                    </SttCard>
                                }

                                {
                                    <SttCard key={uuidv4()} className={classes.card} variant="outlined">
                                        <SttCardHeader title={strings.exameMaisAntigoSemLaudo} component="h3" />
                                        <SttCardContent>
                                            {
                                                produtividadeExamesAntigosSemLaudo.dados ?
                                                    <div className={classes.table}>
                                                        <SttTable>
                                                            <SttTableBody>
                                                                {
                                                                    produtividadeExamesAntigosSemLaudo.dados.length > 0 && produtividadeExamesAntigosSemLaudo.dados.map(item => (
                                                                        <SttTableRow key={item.id} hover={false}>
                                                                            <SttTableCell>{item.nome}</SttTableCell>
                                                                            <SttTableCell align="right">{item.data_exame_mais_antigo}</SttTableCell>
                                                                        </SttTableRow>
                                                                    )) ||
                                                                    <SttTableRow hover={false}>
                                                                        <SttTableCell>{strings.nenhumRegistroEncontrado}</SttTableCell>
                                                                    </SttTableRow>
                                                                }
                                                            </SttTableBody>
                                                        </SttTable>
                                                    </div> :
                                                    <div className={classes.carregando}>
                                                        <SttCircularProgress color="primary" />
                                                    </div>
                                            }
                                        </SttCardContent>
                                    </SttCard>
                                }
                            </SttGrid>
                        }
                    </>
                }

                {
                    !(temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) &&
                    <>
                        {/* Coluna esquerda */}
                        <SttGrid item xs={12} md={6}>
                            {
                                // Caso possua card de laudos por mês atual/anterior
                                <SttCard className={classes.card} variant="outlined">
                                    <SttCardHeader title={strings.examesComLaudo} component="h3" />
                                    <SttCardContent>
                                        {
                                            produtividadeExamesValidosMesAtual.dados ?
                                                <div className={classes.table}>
                                                    <SttTable>
                                                        <SttTableHead>
                                                            <SttTableRow hover={false}>
                                                                <SttTableCell colSpan={2}>{upperFirst(moment(produtividadeExamesValidosMesAtual.mes, 'M').format('MMMM'))}</SttTableCell>
                                                            </SttTableRow>
                                                        </SttTableHead>
                                                        <SttTableBody>
                                                            {
                                                                produtividadeExamesValidosMesAtual.dados?.length > 0 && produtividadeExamesValidosMesAtual.dados.map(item => (
                                                                    <SttTableRow key={item.id} hover={false}>
                                                                        <SttTableCell>{item.nome}</SttTableCell>
                                                                        <SttTableCell align="right">{item.total}</SttTableCell>
                                                                    </SttTableRow>
                                                                )) ||
                                                                <SttTableRow hover={false}>
                                                                    <SttTableCell>{strings.nenhumRegistroEncontrado}</SttTableCell>
                                                                </SttTableRow>
                                                            }
                                                        </SttTableBody>
                                                    </SttTable>
                                                </div> :
                                                <div className={classes.carregando}>
                                                    <SttCircularProgress color="primary" />
                                                </div>
                                        }
                                        {
                                            produtividadeExamesValidosMesAnterior.dados ?
                                                <div className={classes.table}>
                                                    <SttTable>
                                                        <SttTableHead>
                                                            <SttTableRow hover={false}>
                                                                <SttTableCell colSpan={2}>{upperFirst(moment(produtividadeExamesValidosMesAnterior.mes, 'M').format('MMMM'))}</SttTableCell>
                                                            </SttTableRow>
                                                        </SttTableHead>
                                                        <SttTableBody>
                                                            {
                                                                produtividadeExamesValidosMesAnterior.dados?.length > 0 && produtividadeExamesValidosMesAnterior.dados.map(item => (
                                                                    <SttTableRow key={item.id} hover={false}>
                                                                        <SttTableCell>{item.nome}</SttTableCell>
                                                                        <SttTableCell align="right">{item.total}</SttTableCell>
                                                                    </SttTableRow>
                                                                )) ||
                                                                <SttTableRow hover={false}>
                                                                    <SttTableCell>{strings.nenhumRegistroEncontrado}</SttTableCell>
                                                                </SttTableRow>
                                                            }
                                                        </SttTableBody>
                                                    </SttTable>
                                                </div> :
                                                <div className={classes.carregando}>
                                                    <SttCircularProgress color="primary" />
                                                </div>
                                        }
                                    </SttCardContent>
                                </SttCard>
                            }
                        </SttGrid>

                        {/* Coluna direita */}
                        {
                            <SttGrid item xs={12} md={6}>
                                {
                                    <SttCard className={classes.card} variant="outlined">
                                        <SttCardHeader title={strings.examesInvalidos} component="h3" />
                                        <SttCardContent>
                                            {
                                                produtividadeExamesInvalidosMesAtual.dados ?
                                                    <div className={classes.table}>
                                                        <SttTable>
                                                            <SttTableHead>
                                                                <SttTableRow hover={false}>
                                                                    <SttTableCell colSpan={2}>{upperFirst(moment(produtividadeExamesInvalidosMesAtual.mes, 'M').format('MMMM'))}</SttTableCell>
                                                                </SttTableRow>
                                                            </SttTableHead>
                                                            <SttTableBody>
                                                                {
                                                                    produtividadeExamesInvalidosMesAtual.dados?.length > 0 && produtividadeExamesInvalidosMesAtual.dados.map(item => (
                                                                        <SttTableRow key={item.id} hover={false}>
                                                                            <SttTableCell>{item.nome}</SttTableCell>
                                                                            <SttTableCell align="right">{item.total}</SttTableCell>
                                                                        </SttTableRow>
                                                                    )) ||
                                                                    <SttTableRow hover={false}>
                                                                        <SttTableCell>{strings.nenhumRegistroEncontrado}</SttTableCell>
                                                                    </SttTableRow>
                                                                }
                                                            </SttTableBody>
                                                        </SttTable>
                                                    </div> :
                                                    <div className={classes.carregando}>
                                                        <SttCircularProgress color="primary" />
                                                    </div>
                                            }
                                            {
                                                produtividadeExamesInvalidosMesAnterior.dados ?
                                                    <div className={classes.table}>
                                                        <SttTable>
                                                            <SttTableHead>
                                                                <SttTableRow hover={false}>
                                                                    <SttTableCell colSpan={2}>{upperFirst(moment(produtividadeExamesInvalidosMesAnterior.mes, 'M').format('MMMM'))}</SttTableCell>
                                                                </SttTableRow>
                                                            </SttTableHead>
                                                            <SttTableBody>
                                                                {
                                                                    produtividadeExamesInvalidosMesAnterior.dados?.length > 0 && produtividadeExamesInvalidosMesAnterior.dados.map(item => (
                                                                        <SttTableRow key={item.id} hover={false}>
                                                                            <SttTableCell>{item.nome}</SttTableCell>
                                                                            <SttTableCell align="right">{item.total}</SttTableCell>
                                                                        </SttTableRow>
                                                                    )) ||
                                                                    <SttTableRow hover={false}>
                                                                        <SttTableCell>{strings.nenhumRegistroEncontrado}</SttTableCell>
                                                                    </SttTableRow>
                                                                }
                                                            </SttTableBody>
                                                        </SttTable>
                                                    </div> :
                                                    <div className={classes.carregando}>
                                                        <SttCircularProgress color="primary" />
                                                    </div>
                                            }
                                        </SttCardContent>
                                    </SttCard>
                                }
                            </SttGrid>
                        }
                    </>

                }
            </SttGrid>
        </SttContainer >
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(Produtividade);