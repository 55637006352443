import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setLaudar as setLaudarAction } from '../../../redux/actions/laudo';
import { SttButton, SttTranslateHook, } from '@stt-componentes/core';
import Invalidar from '../../exame/invalidar';
import { temPermissaoRBAC } from '../../../security/acl';
import {
    PERMISSOES
} from '../../../common/Constants';


const BotaoInvalidar = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { user, exame, callbackInvalidar } = props;

    const [invalidar, setInvalidar] = useState(false);

    return (
        <>
            {
                temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME) &&
                <>
                    <SttButton
                        variant="contained"
                        danger={+true}
                        onClick={() => setInvalidar(true)}
                    >
                        {strings.invalidar}
                    </SttButton>


                </>
            }

            {
                invalidar &&
                <Invalidar
                    invalidar={invalidar}
                    setInvalidar={setInvalidar}
                    idExame={exame.exame.id_exame}
                    instituicao={exame.exame.id_instituicao}
                    idModalidade={exame.exame.id_modalidade}
                    callback={callbackInvalidar}
                    invalidarSemConfirmacao={true}
                />
            }
        </>
    );
};

BotaoInvalidar.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLaudar: laudar => dispatch(setLaudarAction(laudar))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BotaoInvalidar);