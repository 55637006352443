import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Store } from './store';
import './styles.css';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { SttTranslateHook, SttThemeProvider } from '@stt-componentes/core';
import Home from './paginas';

class App extends Component {

    componentDidMount() {
        window.process = {
            ...window.process,
        };
    }

    componentDidUpdate() {
        window.process = {
            ...window.process,
        };
    }

    render() {
        return (
            <div>
                <NotificationContainer />
                <Provider store={Store}>
                    <SttThemeProvider config={global.gConfig}>
                        <SttTranslateHook.I18nContextProvider modulo={'ecg'}>
                            <Home />
                        </SttTranslateHook.I18nContextProvider>
                    </SttThemeProvider>
                </Provider>
            </div>
        )
    }

    getChildContext() {
        return {
            currentLanguage: this.props.currentLanguage,
            modulo: this.props.modulo,
        };
    }
}

App.childContextTypes = {
    currentLanguage: PropTypes.string.isRequired,
    modulo: PropTypes.string.isRequired
};

export default App;