import React, { useEffect, useState, memo } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../../request';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttAlerta
} from '@stt-componentes/core';
import {
    PERMISSOES
} from '../../../common/Constants';
import moment from 'moment';
import { Util } from '@stt-utilitarios/core';
import { v4 as uuidv4 } from 'uuid';
import EnvioImagens from '../index';
import { temPermissaoRBAC } from '../../../security/acl';
import { CONSTANTES_INTENSIDADE_DOR } from '../../../common/Constants';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const Alerta = memo((props) => {
    return (
        <SttAlerta {...props} />
    )
});

const Detalhes = (props) => {
    const { strings, user, id, voltar } = props;
    const classes = useStyles();
    const [idComponenteEnvioImagens, setIdComponenteEnvioImagens] = useState(Math.random());
    const [solicitacao, setSolicitacao] = useState(null);
    const [dadosSolicitacao, setDadosSolicitacao] = useState([]);
    const [mostrarModalEnvioImagens, setMostrarModalEnvioImagens] = useState(false);
    
    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }

    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => setMostrarAlerta(false));
    

    const tratarDadosSolicitacao = (dados) => {

        if (dados.data_nascimento_paciente) {
            var anos = moment().diff(dados.data_nascimento_paciente, 'years');
            dados.idade_paciente = anos;
        }

        if (dados.cpf_paciente) {
            dados.cpf_paciente = ('00000000000' + dados.cpf_paciente.replace(/[\. ,:-]+/g, "")).slice(-11);
        }

        if (dados.intensidade_dor) {
            dados.intensidade_dor = CONSTANTES_INTENSIDADE_DOR.find((i) => {
                return dados.intensidade_dor === i.id;
            });

            dados.intensidade_dor = dados.intensidade_dor.descricao;
        }

        // Tratar dados dos motivos de solicitação
        if (dados.motivosSolicitacao?.length) {
            let motivosSolicitacao = '';
            dados.motivosSolicitacao.forEach((motivo, indice) => {
                if (motivo.tipo) {
                    motivosSolicitacao += `${motivo.descricao} (${motivo.tipo_formatado})`;
                } else {
                    motivosSolicitacao += motivo.descricao;
                }

                if (indice < dados.motivosSolicitacao.length - 1) {
                    motivosSolicitacao += ', ';
                }
            });

            dados.motivosSolicitacaoFormatado = motivosSolicitacao;
        }

        // Tratar dados da indicação clínica
        if (dados.indicacaoEletro.dorIsquemica) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorIsquemica = dados.indicacaoEletro.dorIsquemica.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.dorNaoIsquemica) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorNaoIsquemica = dados.indicacaoEletro.dorNaoIsquemica.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.dorNaoCardiaca) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorNaoCardiaca = dados.indicacaoEletro.dorNaoCardiaca.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.dorToracica) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorToracica = dados.indicacaoEletro.dorToracica.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.fatorRisco) {
            dados.indicacaoEletro.descricaoIndicacaoEletroFatorRisco = dados.indicacaoEletro.fatorRisco.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.localizacaoDor) {
            dados.indicacaoEletro.descricaoIndicacaoEletroLocalizacaoDor = dados.indicacaoEletro.localizacaoDor.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.medicamento) {
            dados.indicacaoEletro.descricaoIndicacaoEletroMedicamento = dados.indicacaoEletro.medicamento.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.motivoExame) {
            dados.indicacaoEletro.descricaoIndicacaoEletroMotivoExame = dados.indicacaoEletro.motivoExame.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.motivoSolicitacao) {
            dados.indicacaoEletro.descricaoIndicacaoEletroMotivoSolicitacao = dados.indicacaoEletro.motivoSolicitacao.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.sintomaDor) {
            dados.indicacaoEletro.descricaoIndicacaoEletroSintomaDor = dados.indicacaoEletro.sintomaDor.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.valvulopatia) {
            dados.indicacaoEletro.descricaoIndicacaoEletroValvulopatia = dados.indicacaoEletro.valvulopatia.map(e => e.descricao).join(', ');
        }

        for (const tipoDor of dados.indicacaoEletro.tipoDor) {
            if (tipoDor.necessita_complemento) {
                tipoDor.descricao = `${tipoDor.descricao}: ${tipoDor.complemento}`
            }
        }
        if (dados.indicacaoEletro.tipoDor) {
            dados.indicacaoEletro.descricaoIndicacaoEletroTipoDor = dados.indicacaoEletro.tipoDor.map(e => e.descricao).join(', ');
        }

        if(dados.duracao_historico_dor) {
            let duracaoHistoricoDorFormatado = '';

            if(dados.duracao_historico_dor.hours) {
                duracaoHistoricoDorFormatado += `${dados.duracao_historico_dor.hours} hora(s)`;

                if(dados.duracao_historico_dor.minutes) {
                    duracaoHistoricoDorFormatado += ` e ${dados.duracao_historico_dor.minutes} minuto(s)`;
                }
            } else if (dados.duracao_historico_dor.minutes) {
                duracaoHistoricoDorFormatado += `${dados.duracao_historico_dor.minutes} minuto(s)`;
            }

            dados.duracaoHistoricoDorFormatado = duracaoHistoricoDorFormatado;
        } 

        return dados;
    };

    useEffect(() => {
        const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;
        if (id) {
            axios
                .get(`${ELETRO_API_BASE_URL}/solicitacao/${id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { data } = response.data;
                        setSolicitacao(tratarDadosSolicitacao(data));
                        setDadosSolicitacao({
                            id: data.id_solicitacao,
                            instituicao: data.id_instituicao_solicitante,
                            nome_paciente: data.nome_paciente
                        });
                    }
                })
                .catch(err => console.log(err));
        }
    }, [id]);

    const handleAbrirModalEnvioImagens = () => {
        setIdComponenteEnvioImagens(Math.random());
        setMostrarModalEnvioImagens(true);
    }

    const callbackEnvioImagens = () => {
        setOpcoesAlerta([{
            title: strings.ok,
            onClick: () => voltar(true)
        }]);
        setTipoAlerta('success');
        setTituloAlerta(strings.sucesso);
        setMensagemAlerta(strings.exameEnviadoSucesso);
        setOnCloseAlerta(() => () => {
            setMostrarAlerta(false);
            voltar(true);
        })
        setMostrarAlerta(true);
    }

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={voltar}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                !solicitacao
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center">{strings.detalhesSolicitacao}</SttHeading>

                        <SttExpansionPanel
                            title={strings.dadosGerais}
                            children={
                                <div>
                                    <SttTextItem key={uuidv4()} title={strings.dataSolicitacao} content={`${solicitacao.data_solicitacao_formatada}`} />
                                    <SttTextItem key={uuidv4()} title={strings.medicoSolicitante} content={solicitacao.nome_solicitante} />
                                    <SttTextItem key={uuidv4()} title={strings.instituicaoSolicitante} content={solicitacao.nome_instituicao_solicitante} />
                                    <SttTextItem key={uuidv4()} title={strings.funcionarioDigitador} content={solicitacao.nome_digitador} />
                                </div>
                            }
                        />

                        <SttExpansionPanel
                            title={strings.dadosPaciente}
                            children={
                                <div>
                                    <SttTextItem key={uuidv4()} title={strings.nome} content={solicitacao.nome_paciente} />
                                    <SttTextItem key={uuidv4()} title={strings.dataNascimento} content={solicitacao.data_nascimento_formatada} />
                                    <SttTextItem key={uuidv4()} title={strings.idade} content={`${solicitacao.idade_paciente} anos`} />
                                    <SttTextItem key={uuidv4()} title={strings.genero} content={solicitacao.sexo_paciente} />
                                    {solicitacao.cartao_sus_paciente && <SttTextItem key={uuidv4()} title={strings.cns} content={solicitacao.cartao_sus_paciente} />}
                                    {solicitacao.cpf_paciente && <SttTextItem key={uuidv4()} title={strings.cpf} content={Util.util.adicionarMascara(solicitacao.cpf_paciente, '000.000.000-00')} />}
                                    <SttTextItem key={uuidv4()} title={strings.peso} content={solicitacao.peso_paciente} />
                                    <SttTextItem key={uuidv4()} title={strings.altura} content={solicitacao.altura_paciente} />
                                    {solicitacao.profissao_paciente && <SttTextItem key={uuidv4()} title={strings.ocupacao} content={solicitacao.profissao_paciente} />}
                                    {solicitacao.grupo_sanguineo_paciente && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.grupoSanguineo} content={solicitacao.grupo_sanguineo_paciente} />}
                                    {solicitacao.fator_rh_paciente && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.fatorRh} content={solicitacao.fator_rh_paciente} />}
                                    {solicitacao.logradouro_paciente && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.logradouro} content={solicitacao.logradouro_paciente} />}
                                    {solicitacao.bairro_paciente && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.bairro} content={solicitacao.bairro_paciente} />}
                                    {solicitacao.cep_paciente && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.cep} content={Util.util.adicionarMascara(solicitacao.cep_paciente, '00000-000')} />}
                                    {solicitacao.cidade_uf_paciente && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.cidade} content={solicitacao.cidade_uf_paciente} />}
                                    {solicitacao.numero_paciente && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.numero} content={solicitacao.numero_paciente} />}
                                    {
                                        solicitacao.contatosPaciente.map((contato, index) => {
                                            return (
                                                <div key={index}>
                                                    <SttTextItem key={uuidv4()} title={contato.descricao_tipo_contato} content={Util.util.adicionarMascara(contato.contato, '(00) 90000-0000')} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        />

                        <SttExpansionPanel
                            title={strings.indicacaoClinica}
                            children={
                                <div>
                                    <SttTextItem key={uuidv4()} title={strings.motivoExame} content={solicitacao.motivosSolicitacaoFormatado} />
                                    {solicitacao.outros_medicamentos && <SttTextItem key={uuidv4()} title={strings.outrosMedicamentos} content={solicitacao.outros_medicamentos} />}
                                    <SttTextItem key={uuidv4()} title={strings.dorMomentoExame} content={solicitacao.dor_momento_exame ? 'Sim' : 'Não'} />
                                    {solicitacao.intensidade_dor && <SttTextItem key={uuidv4()} title={strings.intensidadeDor} content={solicitacao.intensidade_dor} />}
                                    {solicitacao.observacao && <SttTextItem key={uuidv4()} title={strings.observacao} content={solicitacao.observacao} />}
                                    {solicitacao.cardiopatia_congenita && <SttTextItem key={uuidv4()} title={strings.cardiopatiaCongenita} content={solicitacao.cardiopatia_congenita} />}
                                    {solicitacao.outras_hipoteses_diagnosticas && <SttTextItem key={uuidv4()} title={strings.outrasHipotesesDiagnosticas} content={solicitacao.outras_hipoteses_diagnosticas} />}
                                    {solicitacao.descricao_classificacao_dor && <SttTextItem key={uuidv4()} title={strings.classificacaoDor} content={solicitacao.descricao_classificacao_dor} />}
                                </div>
                            }
                        />

                        {
                            (solicitacao.data_historico_dor_formatada ||
                                solicitacao.duracao_historico_dor ||
                                solicitacao.tempo_historico_dor) &&
                            <SttExpansionPanel
                                title={strings.historicoDor}
                                children={
                                    <div>
                                        {solicitacao.data_historico_dor_formatada && <SttTextItem key={uuidv4()} title={strings.data} content={solicitacao.data_historico_dor_formatada} />}
                                        {solicitacao.duracao_historico_dor && <SttTextItem key={uuidv4()} title={strings.duracao} content={solicitacao.duracaoHistoricoDorFormatado} />}
                                        {solicitacao.tempo_historico_dor && <SttTextItem key={uuidv4()} title={strings.tempoApresentaSintoma} content={solicitacao.tempo_historico_dor + ' ' + solicitacao.descricao_especificacao_tempo_historico_dor} />}
                                    </div>
                                }
                            />
                        }

                        {
                            solicitacao.cid10?.length > 0 &&
                            <SttExpansionPanel
                                title={strings.cid10}
                                children={
                                    <div>
                                        <SttTable>
                                            <SttTableHead>
                                                <SttTableRow>
                                                    <SttTableCell width="40%">
                                                        {strings.categoria}
                                                    </SttTableCell>
                                                    <SttTableCell width="60%">
                                                        {strings.subcategoria}
                                                    </SttTableCell>
                                                </SttTableRow>
                                            </SttTableHead>
                                            <SttTableBody>
                                                {
                                                    solicitacao.cid10.map((row, index) => (
                                                        <SttTableRow key={index}>
                                                            <SttTableCell>{row.descricao_categoria}</SttTableCell>
                                                            <SttTableCell>{row.descricao_subcategoria}</SttTableCell>
                                                        </SttTableRow>
                                                    ))
                                                }
                                            </SttTableBody>
                                        </SttTable>
                                    </div>
                                }
                            />
                        }
                        
                        <SttExpansionPanel
                            title={strings.dadosComplementaresIndicacaoClinica}
                            children={
                                <div>
                                    {solicitacao.indicacaoEletro.descricaoIndicacaoEletroDorIsquemica && <SttTextItem key={uuidv4()} title={strings.dorCardiacaIsquemica} content={solicitacao.indicacaoEletro.descricaoIndicacaoEletroDorIsquemica} />}
                                    {solicitacao.indicacaoEletro.descricaoIndicacaoEletroDorNaoCardiaca && <SttTextItem key={uuidv4()} title={strings.dorNaoCardiaca} content={solicitacao.indicacaoEletro.descricaoIndicacaoEletroDorNaoCardiaca} />}
                                    {solicitacao.indicacaoEletro.descricaoIndicacaoEletroDorNaoIsquemica && <SttTextItem key={uuidv4()} title={strings.dorCardiacaNaoIsquemica} content={solicitacao.indicacaoEletro.descricaoIndicacaoEletroDorNaoIsquemica} />}
                                    {solicitacao.indicacaoEletro.descricaoIndicacaoEletroDorToracica && <SttTextItem key={uuidv4()} title={strings.caracterizacaoDorToracica} content={solicitacao.indicacaoEletro.descricaoIndicacaoEletroDorToracica} />}
                                    {solicitacao.indicacaoEletro.descricaoIndicacaoEletroFatorRisco && <SttTextItem key={uuidv4()} title={strings.comorbidadesFatoresRisco} content={solicitacao.indicacaoEletro.descricaoIndicacaoEletroFatorRisco} />}
                                    {solicitacao.indicacaoEletro.descricaoIndicacaoEletroLocalizacaoDor && <SttTextItem key={uuidv4()} title={strings.localizacaoIrradiacaoDor} content={solicitacao.indicacaoEletro.descricaoIndicacaoEletroLocalizacaoDor} />}
                                    {solicitacao.indicacaoEletro.descricaoIndicacaoEletroMedicamento && <SttTextItem key={uuidv4()} title={strings.medicamentosUso} content={solicitacao.indicacaoEletro.descricaoIndicacaoEletroMedicamento} />}
                                    {solicitacao.indicacaoEletro.descricaoIndicacaoEletroMotivo_exame && <SttTextItem key={uuidv4()} title={strings.motivosExame} content={solicitacao.indicacaoEletro.descricaoIndicacaoEletroMotivo_exame} />}
                                    {solicitacao.indicacaoEletro.descricaoIndicacaoEletroSintomaDor && <SttTextItem key={uuidv4()} title={strings.sintomasAssociados} content={solicitacao.indicacaoEletro.descricaoIndicacaoEletroSintomaDor} />}
                                    {solicitacao.indicacaoEletro.descricaoIndicacaoEletroValvulopatia && <SttTextItem key={uuidv4()} title={strings.valvulopatia} content={solicitacao.indicacaoEletro.descricaoIndicacaoEletroValvulopatia} />}
                                    {solicitacao.indicacaoEletro.descricaoIndicacaoEletroTipoDor && <SttTextItem key={uuidv4()} title={strings.tipoDor} content={solicitacao.indicacaoEletro.descricaoIndicacaoEletroTipoDor} />}
                                </div>
                            }
                        />

                        {
                            temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME) &&
                            <SttButton
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={handleAbrirModalEnvioImagens}
                                nomarginleft="true"
                            >
                                {strings.enviarImagens}
                            </SttButton>
                        }
                    </>
            }

            {
                temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME) &&
                <EnvioImagens
                    open={mostrarModalEnvioImagens}
                    resetFormulario={() => {
                        setMostrarModalEnvioImagens(false);
                    }}
                    solicitacao={dadosSolicitacao}
                    callbackFinalizarEnvio={callbackEnvioImagens}
                    key={idComponenteEnvioImagens}
                    voltar={() => voltar(true)}
                />
            }

            <Alerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={() => onCloseAlerta()}
            />
        </SttContainer>
    )

}

Detalhes.propTypes = {
    strings: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    voltar: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(Detalhes);