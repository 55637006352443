import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import { temPermissaoRBAC, temPermissaoRede } from '../../security/acl';
import { PERMISSOES } from '../../common/Constants';
import Imagens from '../../componentes/laudo/imagens';
import { setAtualizarBusca as setAtualizarBuscaAction } from '../../redux/actions/exame';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttAlerta,
    SttGrid,
    SttTranslateHook,
    SttTable,
    SttTableHead,
    SttTableRow,
    SttTableCell,
    SttTableBody,
    SttNotification,
    SttAlertTitle
} from '@stt-componentes/core';
import { v4 as uuidv4 } from 'uuid';
import Priorizar from './priorizar';
import Invalidar from './invalidar';
import { SITUACAO_LAUDO } from '../../common/Constants';
import Cid10 from './cid-10';
import Decs from './decs';
import DescritoresSBC from './sbc';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    notificationContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    notification: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    fonteProtocolo: {
        fontFamily: '"Noto Sans"'
    },
    divProtocoloRctm: {
        display: 'flex',
        gap: theme.spacing(2)
    },
    fileCopyIcon: {
        fontSize: '1.2em',
        cursor: 'pointer',
        color: '#0A6FB8'
    }
}));

const DadosExame = (props) => {
    const { voltar, idExame, user, imprimirFn, imprimirProtocoloFn, imprimirTermoFn, ocultarBotoes = false } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const navigate = useNavigate();
    const { id } = useParams();
    const [protocoloCopiado, setProtocoloCopiado] = useState(false);

    const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;
    const [exame, setExame] = useState(null);
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [priorizar, setPriorizar] = useState(false);
    const [invalidar, setInvalidar] = useState(false);
    const [permissaoBtns, setPermissaoBtns] = useState([]);

    useEffect(() => {
        if (id) {
            navigate('/exames'), { replace: true };
            navigate('/exames/visualizar', { state: { exame: Buffer.from(id, 'base64').toString('utf-8') } });
        }
    }, []);

    const opcoesAlerta = [{
        title: strings.ok,
        onClick: voltar
    }];

    useEffect(() => {
        if (user.perfisRBAC && exame) {
            let rede;
            user.perfisRBAC.forEach(p => {
                p.redes && p.redes.forEach(r => {
                    if (r.id === exame.exame.id_rede_telemedicina) {
                        rede = r;
                    }
                });
            });
            if (rede) {
                setPermissaoBtns([
                    temPermissaoRede(rede[0], PERMISSOES.INVALIDAR_EXAME),
                    temPermissaoRede(rede[0], PERMISSOES.PRIORIZAR_EXAME)
                ]);
            }
        }
    }, [user, exame]);

    useEffect(() => {
        if (idExame) {
            axios
                .get(`${ELETRO_API_BASE_URL}/exame/${idExame}`, { headers: getHeaders() })
                .then((response) => {
                    const { data } = response;
                    setExame(tratarDadosExame(data));
                })
                .catch(err => {
                    console.log(err);
                    const { response } = err;
                    let msg = strings.mensagemErroGeral;
                    let arrMensagem = [];
                    if (response) {
                        const { data } = response;
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                    }
                    setMensagemErro(msg);
                    setErro(true);
                });
        }
    }, [idExame]);

    const tratarDadosExame = (dados) => {
        // Tratar dados dos motivos de solicitação
        if (dados.indicacaoEletro?.motivosSolicitacao?.length) {
            dados.indicacaoEletro.motivosSolicitacaoFormatado = dados.indicacaoEletro.motivosSolicitacao.map(motivo => {
                if (motivo.tipo) {
                    return `${motivo.descricao} (${motivo.tipo_formatado})`;
                }

                return motivo.descricao;
            }).join(', ');
        }

        // Tratar dados da indicação clínica
        if (dados.indicacaoEletro?.dorIsquemica) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorIsquemica = dados.indicacaoEletro.dorIsquemica.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro?.dorNaoIsquemica) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorNaoIsquemica = dados.indicacaoEletro.dorNaoIsquemica.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro?.dorNaoCardiaca) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorNaoCardiaca = dados.indicacaoEletro.dorNaoCardiaca.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro?.dorToracica) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorToracica = dados.indicacaoEletro.dorToracica.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro?.fatorRisco) {
            dados.indicacaoEletro.descricaoIndicacaoEletroFatorRisco = dados.indicacaoEletro.fatorRisco.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro?.localizacaoDor) {
            dados.indicacaoEletro.descricaoIndicacaoEletroLocalizacaoDor = dados.indicacaoEletro.localizacaoDor.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro?.medicamento) {
            dados.indicacaoEletro.descricaoIndicacaoEletroMedicamento = dados.indicacaoEletro.medicamento.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro?.motivoExame) {
            dados.indicacaoEletro.descricaoIndicacaoEletroMotivoExame = dados.indicacaoEletro.motivoExame.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro?.sintomaDor) {
            dados.indicacaoEletro.descricaoIndicacaoEletroSintomaDor = dados.indicacaoEletro.sintomaDor.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro?.valvulopatia) {
            dados.indicacaoEletro.descricaoIndicacaoEletroValvulopatia = dados.indicacaoEletro.valvulopatia.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro?.classificacaoDor) {
            dados.indicacaoEletro.descricaoIndicacaoEletroClassificacaoDor = dados.indicacaoEletro.classificacaoDor.descricao;
        }

        if (dados.indicacaoEletro?.tipoDor) {
            for (const tipoDor of dados.indicacaoEletro.tipoDor) {
                if (tipoDor.necessita_complemento) {
                    tipoDor.descricao = `${tipoDor.descricao}: ${tipoDor.complemento}`
                }
            }
            dados.indicacaoEletro.descricaoIndicacaoEletroTipoDor = dados.indicacaoEletro.tipoDor.map(e => e.descricao).join(', ');
        }

        if(dados.indicacaoEletro?.descricaoHistoricoDor?.duracao_historico_dor) {
            let duracaoHistoricoDorFormatado = '';

            if(dados.indicacaoEletro.descricaoHistoricoDor.duracao_historico_dor.hours) {
                duracaoHistoricoDorFormatado += `${dados.indicacaoEletro.descricaoHistoricoDor.duracao_historico_dor.hours} hora(s)`;

                if(dados.indicacaoEletro.descricaoHistoricoDor.duracao_historico_dor.minutes) {
                    duracaoHistoricoDorFormatado += ` e ${dados.indicacaoEletro.descricaoHistoricoDor.duracao_historico_dor.minutes} minuto(s)`;
                }
            } else if (dados.indicacaoEletro.descricaoHistoricoDor.duracao_historico_dor.minutes) {
                duracaoHistoricoDorFormatado += `${dados.indicacaoEletro.descricaoHistoricoDor.duracao_historico_dor.minutes} minuto(s)`;
            }

            dados.indicacaoEletro.descricaoHistoricoDor.duracaoHistoricoDorFormatado = duracaoHistoricoDorFormatado;
        } 

        // Tratamento de dados da indicação preenchidos no momento do envio das imagens
        if (dados.exame?.dorToracica) {
            dados.exame.descricaoDorToracicaRealizacaoExame = dados.exame.dorToracica.map(e => e.descricao).join(', ');
        }

        if (dados.exame?.localizacaoDor) {
            dados.exame.descricaoLocalizacaoDorRealizacaoExame = dados.exame.localizacaoDor.map(e => e.descricao).join(', ');
        }

        if (dados.exame?.tipoDor) {
            dados.exame.descricaoTipoDorRealizacaoExame = dados.exame.tipoDor.map(e => {
                return e.complemento ? e.complemento : e.descricao
            }).join(', ');
        }

        return dados;
    };

    return (
        <SttContainer>
            {
                !exame
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                        <SttAlerta
                            open={erro}
                            title={strings.erro}
                            message={mensagemErro}
                            type="error"
                            options={opcoesAlerta}
                            onClose={voltar}
                        />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center">{exame.exame.descricao_exame}</SttHeading>
                        <SttExpansionPanel
                            title={strings.paciente}
                            children={
                                <div>
                                    {exame.exame.nome_paciente && <SttTextItem key={uuidv4()} title={strings.nome} content={exame.exame.nome_paciente} />}
                                    {exame.exame.data_nascimento_paciente_formatada && <SttTextItem key={uuidv4()} title={strings.dataNascimento} content={exame.exame.data_nascimento_paciente_formatada} />}
                                    {exame.exame.sexo_paciente && <SttTextItem key={uuidv4()} title={strings.sexo} content={exame.exame.sexo_paciente} />}
                                    {exame.indicacaoEletro.peso && <SttTextItem key={uuidv4()} title={strings.peso} content={`${exame.indicacaoEletro.peso} kg`} />}
                                    {exame.indicacaoEletro.altura && <SttTextItem key={uuidv4()} title={strings.altura} content={`${exame.indicacaoEletro.altura} cm`} />}
                                    {exame.exame.idade_paciente != null && <SttTextItem key={uuidv4()} title={strings.idade} content={exame.exame.idade_paciente} />}
                                    {exame.exame.cartao_sus_paciente && <SttTextItem key={uuidv4()} title={strings.cns} content={exame.exame.cartao_sus_paciente} />}
                                    {exame.exame.profissao_paciente && <SttTextItem key={uuidv4()} title={strings.profissao} content={exame.exame.profissao_paciente} />}
                                </div>
                            }
                        />


                        <SttExpansionPanel
                            title={strings.dadosExame}
                            children={
                                <div>
                                    {exame.exame.descricao_modalidade && <SttTextItem key={uuidv4()} title={strings.modalidade} content={exame.exame.descricao_modalidade} />}
                                    {exame.exame.data_exame && <SttTextItem key={uuidv4()} title={strings.dataExame} content={`${exame.exame.data_exame} ${global.gConfig.sufixo_data_hora}`} />}
                                    {exame.exame.protocolo_rctm &&
                                        <div className={classes.divProtocoloRctm}>
                                            <SttTextItem classContent={classes.fonteProtocolo} key={uuidv4()} title={strings.protocolo} content={exame.exame.protocolo_rctm} />
                                            <Tooltip title={protocoloCopiado ? strings.protocoloCopiado : strings.copiar} arrow
                                                onClick={() => {
                                                    navigator.clipboard.writeText(exame.exame.protocolo_rctm);
                                                    setProtocoloCopiado(true);
                                                }} onClose={() => setProtocoloCopiado(false)}>
                                                <FileCopyIcon className={classes.fileCopyIcon} />
                                            </Tooltip>

                                        </div>
                                    }
                                    {exame.exame.requisicao && <SttTextItem key={uuidv4()} title={strings.requisicao} content={exame.exame.requisicao} />}
                                    {exame.exame.nome_instituicao && <SttTextItem key={uuidv4()} title={strings.instituicao} content={exame.exame.nome_instituicao} />}
                                    {exame.exame.nome_medico_solicitante && <SttTextItem key={uuidv4()} title={strings.medicoSolicitante} content={exame.exame.nome_medico_solicitante} />}
                                    {exame.exame.nome_medico_executor && <SttTextItem key={uuidv4()} title={strings.medicoExecutor} content={exame.exame.nome_medico_executor} />}
                                    {exame.exame.observacao_tecnica && <SttTextItem key={uuidv4()} title={strings.observacaoTecnico} content={exame.exame.observacao_tecnica} />}
                                    {exame.exame.descricaoDorToracica && <SttTextItem key={uuidv4()} title={strings.dorToracica} content={exame.exame.descricaoDorToracica} />}
                                    {exame.exame.descricaoLocalizacaoDor && <SttTextItem key={uuidv4()} title={strings.localizacaoIrradiacaoDor} content={exame.exame.descricaoLocalizacaoDor} />}
                                    {exame.exame.descricaoTipoDor && <SttTextItem key={uuidv4()} title={strings.tipoDor} content={exame.exame.descricaoTipoDor} />}
                                </div>
                            }
                        />

                        {
                            exame.indicacaoEletro &&
                            <>
                                <SttExpansionPanel
                                    title={strings.indicacaoClinica}
                                    children={
                                        <div>
                                            {exame.indicacaoEletro.motivosSolicitacaoFormatado && <SttTextItem key={uuidv4()} title={strings.motivoExame} content={exame.indicacaoEletro.motivosSolicitacaoFormatado} />}
                                            {exame.indicacaoEletro.outros_medicamentos && <SttTextItem key={uuidv4()} title={strings.outrosMedicamentos} content={exame.indicacaoEletro.outros_medicamentos} />}
                                            <SttTextItem key={uuidv4()} title={strings.dorMomentoExame} content={exame.indicacaoEletro.dor_momento_exame ? 'Sim' : 'Não'} />
                                            {exame.indicacaoEletro.intensidade_dor && <SttTextItem key={uuidv4()} title={strings.intensidadeDor} content={exame.indicacaoEletro.intensidade_dor} />}
                                            {exame.indicacaoEletro.observacao && <SttTextItem key={uuidv4()} title={strings.observacao} content={exame.indicacaoEletro.observacao} />}
                                            {exame.indicacaoEletro.cardiopatia_congenita && <SttTextItem key={uuidv4()} title={strings.cardiopatiaCongenita} content={exame.indicacaoEletro.cardiopatia_congenita} />}
                                            {exame.indicacaoEletro.outras_hipoteses_diagnosticas && <SttTextItem key={uuidv4()} title={strings.outrasHipotesesDiagnosticas} content={exame.indicacaoEletro.outras_hipoteses_diagnosticas} />}
                                            {exame.indicacaoEletro.descricaoIndicacaoEletroClassificacaoDor && <SttTextItem key={uuidv4()} title={strings.classificacaoDor} content={exame.indicacaoEletro.classificacaoDor.descricao} />}
                                        </div>
                                    }
                                />

                                {
                                    (exame.indicacaoEletro.descricaoHistoricoDor?.data_historico_dor_formatada ||
                                        exame.indicacaoEletro.descricaoHistoricoDor?.duracao_historico_dor ||
                                        exame.indicacaoEletro.descricaoHistoricoDor?.tempo_historico_dor) &&
                                    <SttExpansionPanel
                                        title={strings.historicoDor}
                                        children={
                                            <div>
                                                {exame.indicacaoEletro.descricaoHistoricoDor.data_historico_dor_formatada && <SttTextItem key={uuidv4()} title={strings.data} content={exame.indicacaoEletro.descricaoHistoricoDor.data_historico_dor_formatada} />}
                                                {exame.indicacaoEletro.descricaoHistoricoDor.duracao_historico_dor && <SttTextItem key={uuidv4()} title={strings.duracao} content={exame.indicacaoEletro.descricaoHistoricoDor.duracaoHistoricoDorFormatado} />}
                                                {exame.indicacaoEletro.descricaoHistoricoDor.tempo_historico_dor && <SttTextItem key={uuidv4()} title={strings.tempoApresentaSintoma} content={exame.indicacaoEletro.descricaoHistoricoDor.tempo_historico_dor + ' ' + exame.indicacaoEletro.descricaoHistoricoDor.descricao_especificacao_tempo_historico_dor} />}
                                            </div>
                                        }
                                    />
                                }
                            </>
                        }

                        {
                            exame.indicacaoEletro?.cid10?.length > 0 &&
                            <SttExpansionPanel
                                title={strings.cid10}
                                children={
                                    <div>
                                        <SttTable>
                                            <SttTableHead>
                                                <SttTableRow>
                                                    <SttTableCell width="40%">
                                                        {strings.categoria}
                                                    </SttTableCell>
                                                    <SttTableCell width="60%">
                                                        {strings.subcategoria}
                                                    </SttTableCell>
                                                </SttTableRow>
                                            </SttTableHead>
                                            <SttTableBody>
                                                {
                                                    exame.indicacaoEletro.cid10.map((row, index) => (
                                                        <SttTableRow key={index}>
                                                            <SttTableCell>
                                                                {
                                                                    row.codigo_cid10_categoria ?
                                                                        `${row.codigo_cid10_categoria} - ${row.descricao_categoria}` :
                                                                        row.descricao_categoria
                                                                }
                                                            </SttTableCell>
                                                            <SttTableCell>
                                                                {
                                                                    row.codigo_cid10_subcategoria ?
                                                                        `${row.codigo_cid10_subcategoria} - ${row.descricao_subcategoria}` :
                                                                        row.descricao_subcategoria
                                                                }
                                                            </SttTableCell>
                                                        </SttTableRow>
                                                    ))
                                                }
                                            </SttTableBody>
                                        </SttTable>
                                    </div>
                                }
                            />
                        }

                        {
                            exame.indicacaoEletro && exame.indicacaoEletro.constructor === Object && Object.keys(exame.indicacaoEletro).length > 0 &&
                            <SttExpansionPanel
                                title={strings.dadosComplementaresIndicacaoClinica}
                                children={
                                    <div>
                                        {exame.indicacaoEletro.descricaoIndicacaoEletroDorIsquemica && <SttTextItem key={uuidv4()} title={strings.dorCardiacaIsquemica} content={exame.indicacaoEletro.descricaoIndicacaoEletroDorIsquemica} />}
                                        {exame.indicacaoEletro.descricaoIndicacaoEletroDorNaoCardiaca && <SttTextItem key={uuidv4()} title={strings.dorNaoCardiaca} content={exame.indicacaoEletro.descricaoIndicacaoEletroDorNaoCardiaca} />}
                                        {exame.indicacaoEletro.descricaoIndicacaoEletroDorNaoIsquemica && <SttTextItem key={uuidv4()} title={strings.dorCardiacaNaoIsquemica} content={exame.indicacaoEletro.descricaoIndicacaoEletroDorNaoIsquemica} />}
                                        {exame.indicacaoEletro.descricaoIndicacaoEletroDorToracica && <SttTextItem key={uuidv4()} title={strings.caracterizacaoDorToracica} content={exame.indicacaoEletro.descricaoIndicacaoEletroDorToracica} />}
                                        {exame.indicacaoEletro.descricaoIndicacaoEletroFatorRisco && <SttTextItem key={uuidv4()} title={strings.comorbidadesFatoresRisco} content={exame.indicacaoEletro.descricaoIndicacaoEletroFatorRisco} />}
                                        {exame.indicacaoEletro.descricaoIndicacaoEletroLocalizacaoDor && <SttTextItem key={uuidv4()} title={strings.localizacaoIrradiacaoDor} content={exame.indicacaoEletro.descricaoIndicacaoEletroLocalizacaoDor} />}
                                        {exame.indicacaoEletro.descricaoIndicacaoEletroMedicamento && <SttTextItem key={uuidv4()} title={strings.medicamentosUso} content={exame.indicacaoEletro.descricaoIndicacaoEletroMedicamento} />}
                                        {exame.indicacaoEletro.descricaoIndicacaoEletroMotivoExame && <SttTextItem key={uuidv4()} title={strings.motivosExame} content={exame.indicacaoEletro.descricaoIndicacaoEletroMotivoExame} />}
                                        {exame.indicacaoEletro.descricaoIndicacaoEletroSintomaDor && <SttTextItem key={uuidv4()} title={strings.sintomasAssociados} content={exame.indicacaoEletro.descricaoIndicacaoEletroSintomaDor} />}
                                        {exame.indicacaoEletro.descricaoIndicacaoEletroValvulopatia && <SttTextItem key={uuidv4()} title={strings.valvulopatia} content={exame.indicacaoEletro.descricaoIndicacaoEletroValvulopatia} />}
                                        {exame.indicacaoEletro.descricaoIndicacaoEletroTipoDor && <SttTextItem key={uuidv4()} title={strings.tipoDor} content={exame.indicacaoEletro.descricaoIndicacaoEletroTipoDor} />}
                                    </div>
                                }
                            />
                        }

                        <SttExpansionPanel
                            title={strings.realizacaoExame}
                            children={
                                <div>
                                    {<SttTextItem key={uuidv4()} title={strings.dorMomentoExame} content={exame.exame.dor_momento_exame ? strings.sim : strings.nao} />}
                                    {exame.exame.intensidade_dor && <SttTextItem key={uuidv4()} title={strings.intensidadeDor} content={exame.exame.intensidade_dor} />}
                                    {exame.exame.descricaoLocalizacaoDorRealizacaoExame && <SttTextItem key={uuidv4()} title={strings.localizacaoIrradiacaoDor} content={exame.exame.descricaoLocalizacaoDorRealizacaoExame} />}
                                    {exame.exame.descricaoTipoDorRealizacaoExame && <SttTextItem key={uuidv4()} title={strings.tipoDor} content={exame.exame.descricaoTipoDorRealizacaoExame} />}
                                    {exame.exame.descricaoDorToracicaRealizacaoExame && <SttTextItem key={uuidv4()} title={strings.dorToracica} content={exame.exame.descricaoDorToracicaRealizacaoExame} />}
                                </div>
                            }
                        />

                        {
                            !exame.exame.valido &&
                            <SttNotification severity="info" className={classes.notification}>
                                <SttAlertTitle>{strings.exameInvalidado}</SttAlertTitle>
                                <div className={classes.notificationContent}>
                                    <span>{`${strings.motivo}: ${exame.exame.motivo_invalidacao}`}</span>
                                    <span>{`${strings.responsavel}: ${exame.exame.responsavel_invalidacao}`}</span>
                                    <span>{`${strings.data}: ${exame.exame.data_invalidacao}`}</span>
                                </div>
                            </SttNotification>
                        }

                        {
                            temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_LAUDO) && exame.laudo &&
                            <SttExpansionPanel
                                title={strings.laudo}
                                children={
                                    <div>
                                        {
                                            exame.exame.grave &&
                                            <SttNotification severity="error" className={classes.notification}>
                                                <SttAlertTitle>{strings.laudoImportante}</SttAlertTitle>
                                                <div className={classes.notificationContent}>
                                                    <span>{strings.avisoLaudoImportante}</span>
                                                </div>
                                            </SttNotification>
                                        }
                                        {exame?.descritoresLaudo?.cid10?.length > 0 && <Cid10 cid10={exame.descritoresLaudo.cid10} />}
                                        {exame?.descritoresLaudo?.decs?.length > 0 && <Decs decs={exame.descritoresLaudo.decs} />}
                                        {exame?.descritoresLaudo?.descritoresSbc?.length > 0 && <DescritoresSBC sbcs={exame.descritoresLaudo.descritoresSbc} />}
                                        <div dangerouslySetInnerHTML={{ __html: exame.laudo }}></div>
                                    </div>
                                }
                            />
                        }

                        {
                            temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_IMAGEM) &&
                            (
                                (
                                    exame.imagensIncompletas &&
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <SttNotification severity="info">{strings.imagensIndisponiveis}</SttNotification>
                                        </SttGrid>
                                    </SttGrid>

                                ) ||
                                <Imagens idExame={idExame} />
                            )
                        }

                        {
                            exame.exame.valido && !ocultarBotoes &&
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            imprimirFn(idExame)
                                        }}
                                        nomarginleft="true"
                                    >
                                        {strings.imprimir}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            imprimirProtocoloFn({ ...exame.exame, id: exame.exame.id_exame })
                                        }}
                                    >
                                        {strings.protocolo}
                                    </SttButton>
                                    {
                                        (permissaoBtns[1] ||
                                            (permissaoBtns[1] === undefined && temPermissaoRBAC(user, PERMISSOES.PRIORIZAR_EXAME))) &&
                                        exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO &&
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setPriorizar(true)}
                                        >
                                            {strings.priorizar}
                                        </SttButton>
                                    }
                                    {
                                        (((permissaoBtns[0] ||
                                            (permissaoBtns[0] === undefined && temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME))) &&
                                            exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO) ||
                                            (temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME_LAUDADO) &&
                                                exame.exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO)) &&
                                        exame.exame.valido &&
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setInvalidar(true)}
                                        >
                                            {strings.invalidar}
                                        </SttButton>
                                    }
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            imprimirTermoFn(exame.exame)
                                        }}
                                    >
                                        {strings.imprimirTermo}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        }

                        {
                            (permissaoBtns[1] ||
                                (permissaoBtns[1] === undefined && temPermissaoRBAC(user, PERMISSOES.PRIORIZAR_EXAME))) &&
                            priorizar && exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO && exame.exame.valido &&
                            <Priorizar priorizar={priorizar} setPriorizar={setPriorizar} idExame={idExame} idInstituicao={exame.exame.id_instituicao} idRedeTelemedicina={exame.exame.id_rede_telemedicina} callback={voltar} user={user}/>
                        }
                        {
                            (((permissaoBtns[0] ||
                                (permissaoBtns[0] === undefined && temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME))) &&
                                exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO) ||
                                (temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME_LAUDADO) &&
                                    exame.exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO)) &&
                            invalidar &&
                            exame.exame.valido &&
                            <Invalidar
                                invalidar={invalidar}
                                setInvalidar={setInvalidar}
                                idExame={idExame}
                                idModalidade={exame.exame.id_modalidade}
                                callback={voltar} />
                        }
                    </>
            }
        </SttContainer>
    )

}

DadosExame.propTypes = {
    strings: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };  
};

const mapDispatchToProps = dispatch => {
    return {
        setAtualizarBusca: atualizar => dispatch(setAtualizarBuscaAction(atualizar))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DadosExame);