import React, { useState, useEffect, useContext } from 'react';
import DadosGerais from '../informacoes-gerais/dadosGerais';
import DadosExame from '../dados-exame';
import { EQUIPAMENTO } from './fieldNames';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Anexos from '../anexos';
import {
    SttModal,
    SttButton,
    SttStepper,
    SttMobileStepper,
    SttTranslateHook
} from '@stt-componentes/core';
import SttHidden from '@stt-componentes/core/dist/lib/SttLayout/SttHidden';

const useStyles = makeStyles(theme => ({
    mobileStepper: {
        marginBottom: theme.spacing(1)
    }
}));

const InformacoesGerais = (props) => {
    const {
        nomePaciente,
        confirmarFecharModal,
        user,
        open,
        callbackFinalizado,
        setFieldValue,
        equipamentos,
        validateForm,
        setTouched,
        touched,
        values,
        steps,
        stepAtual,
        setStepAtual
    } = props;
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [selecionarAnexos, setSelecionarAnexos] = useState(false);

    useEffect(() => {
        if (equipamentos?.length === 1) {
            setFieldValue(EQUIPAMENTO, equipamentos[0]);
        }
    }, [equipamentos]);

    const proximoStep = () => {
        setStepAtual(stepAtual + 1);
        setSelecionarAnexos(true);
    }

    const stepAnterior = () => {
        setStepAtual(stepAtual - 1);
        setSelecionarAnexos(false);
    }

    const finalizarConjuntoSteps = (val) => {
        callbackFinalizado(val);
    }

    const tratarComponenteStepper = () => {
        switch (stepAtual) {
            case 0:
                return <DadosGerais strings={strings} user={user} equipamentos={equipamentos} />;
            case 1:
                return <DadosExame strings={strings} user={user} />;
            case 2:
                return <Anexos
                    nomePaciente={nomePaciente}
                    strings={strings}
                    steps={steps}
                    open={selecionarAnexos}
                    validateForm={validateForm}
                    touched={touched}
                    setTouched={setTouched}
                    values={values}
                    setFieldValue={setFieldValue}
                />

        }
    }

    return (
        <SttModal
            title={strings.envioImagens}
            open={open}
            outModalClose={confirmarFecharModal}
            iconClose={confirmarFecharModal}
            maxWidth="lg"
            fullWidth={true}
            children={
                <>
                    <SttHidden only={['xs', 'sm']}>
                        <SttStepper steps={steps.map((step) => step.titulo)} activeStep={stepAtual} />
                        {
                            tratarComponenteStepper()
                        }
                    </SttHidden>
                    <SttHidden mdUp>
                        {
                            tratarComponenteStepper()
                        }
                        <SttMobileStepper
                            className={classes.mobileStepper}
                            steps={steps.length}
                            activeStep={stepAtual}
                        />
                    </SttHidden>
                </>
            }
            footer={
                <>
                    {
                        stepAtual > 0 &&
                        <SttButton
                            variant="outlined"
                            color="primary"
                            onClick={stepAnterior}>
                            {strings.anterior}
                        </SttButton>
                    }
                    {
                        stepAtual < steps.length - 1 &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            onClick={() => validateForm().then((erros) => {
                                if (Object.keys(erros).length) {
                                    setTouched({ ...touched, ...erros });
                                    return;
                                }
                                proximoStep();
                            })}>
                            {strings.proximo}
                        </SttButton>
                    }
                    {
                        stepAtual === steps.length - 1 &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            onClick={finalizarConjuntoSteps}>
                            {strings.enviarImagens}
                        </SttButton>
                    }
                    <SttButton
                        variant="outlined"
                        color="secondary"
                        onClick={confirmarFecharModal}
                    >
                        {strings.cancelar}
                    </SttButton>
                </>
            }
        />
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(InformacoesGerais);